﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />
.social {

    a {
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        padding-left: 30px;
        line-height: 30px;
        margin: 5px;
    }

    &__twitter {
        background-image: url('/a/dist/img/social/twitter.svg');
    }

    &__linkedin {
        background-image: url('/a/dist/img/social/linkedin.svg');
    }

    &__stack-overflow {
        background-image: url('/a/dist/img/social/stackoverflow.svg');
    }

    &__github {
        background-image: url('/a/dist/img/social/github.svg');
    }

    &__instagram {
        background-image: url('/a/dist/img/social/instagram.svg');
    }

    &__facebook {
        background-image: url('/a/dist/img/social/facebook-square.svg');
    }

    &__youtube {
        background-image: url('/a/dist/img/social/youtube.svg');
    }

    &__googleplus {
        background-image: url('/a/dist/img/social/googleplus-square.svg');
    }
}
