﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />
.media {
    border-left: $brand-subtle dotted 4px;
    padding-left: 10px;
    background-color: $gray-100;

    &__heading {
        font-size: $h5-font-size;
    }

    &__link {
        color: inherit;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}