﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />
@include media-breakpoint-up(md) {
    .site-nav {
        position: fixed;
        top: 50%;
        left: 0;
        background: $gray-900;
        opacity: .8;
        z-index: 100;
        border-radius: 0 5px 5px 0;
        margin-top: -175.5px;
        padding-top: 25px;
        transition: left ease-in-out .1s;

        .nav-title {
            font-size: .6rem;
            position: absolute;
            top: 3px;
            left: 2px;
            color: $brand-subtle;
        }

        .list__link {
            width: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &:hover {
            .nav-title {
                left: 60px;
            }

            .list__link {
                width: 180px;
                padding-left: 10px;
            }
        }

        .close-site-nav {
            display: none;
        }
    }

    .menu-mobile,
    .close-site-nav {
        display: none;
    }
}

@include media-breakpoint-down(sm) {

    .menu-mobile,
    .close-site-nav {
        position: fixed;
        bottom: 5px;
        left: 5px;
        background: $dark-bg;
        padding: 5px;
        font-size: 2.5rem;
        line-height: 19px;
        opacity: .9;
        color: $white;
        z-index: 100;
        border: 0;
    }

    .close-site-nav {
        color: $brand-subtle;
    }

    .site-nav {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $gray-900;
        opacity: .9;
        z-index: 100;
        padding-top: 10px;
        transition: max-width ease-in-out .25s;
        max-width: 0;
        overflow: hidden;

        .close-site-nav {
            display: none;
        }

        &.active {
            max-width: 100vw;

            .close-site-nav {
                display: block;
            }
        }

        .nav-title {
            padding: 10px;
            position: relative;
            color: $brand-subtle;
        }

        .list--nav {
            margin: 0;
            padding: 0;

            .list__link {
                padding: 10px;
                color: $white;
                border-bottom: 0;
                overflow: hidden;
                transition: width ease-in-out .1s;
                display: inline-block;

                &:hover {
                    color: $link-hover-color;
                }

                .home-link {
                    font-size: 2.4rem;
                    margin-bottom: 2px;
                }
            }
        }
    }

    body {
        &.menu-open {
            overflow: hidden;
        }
    }
}
