﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />
.article {
    padding-top: 50px;
    max-width: 100%;
    overflow: hidden;

    img {
        max-width: 100%;
    }

    &__img-holder {
        background-image: url('#{$image-url}/loading.svg');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 30%;
        position: relative;
        max-width: 100%;
        background-color: transparent;
        margin: 40px auto;
        display: block;

        img,
        picture {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}

.latest-blog-posts {
    padding: 50px;

    .media {
        img {
            max-width: 100px;
        }
    }
}
