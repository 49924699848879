﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />

.media {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
