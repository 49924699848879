﻿@import '../../../../node_modules/bootstrap/scss/utilities/text';
@import '../../../../node_modules/bootstrap/scss/utilities/screenreaders';


.left {
    float: left;
}

.right {
    float: right;
}