﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />

.list {
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        line-height: 1;
    }

    &__link {
        @include media-breakpoint-up(md) {
            width: 45px;
        }

        color: $white;
        border-bottom: 0;
        overflow: hidden;
        transition: width ease-in-out .1s;
        line-height: 1;
        padding: 10px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
    }

    &--nav {
        @include list-unstyled;
        display: flex;
        flex-direction: column;
    }

    &--unstyled {
        @include list-unstyled;
    }
}