﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />
.development {
    .stack {
        border-right: $gray-900 solid 2px;
        text-align: center;

        &-logo-wrapper {
            padding: 10px;
        }

        &-logo {
            max-width: 100%;
            width: 100px;
            display: block;
            margin: 0 auto;
            padding-bottom: 20px;

            &.visual-studio,
            &.sql {
                padding-top: 20px;
            }
        }
    }

    .stack:nth-last-child(1) {
        border-right: 0;
    }
}
