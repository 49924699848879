﻿/// <reference path="../01-settings/settings" />
/// <reference path="../02-tools/tools" />

.example {
    margin-bottom: 60px;

    &__mockup {
        border: $gray-300 solid 1px;
        border-top: $gray-300 solid 2em;
        position: relative;
        border-radius: 3px 3px 0 0;
        height: 345px;

        &::before {
            display: block;
            position: absolute;
            content: '';
            top: -1.25em;
            left: 1em;
            width: .5em;
            height: .5em;
            border-radius: 50%;
            background-color: $brand-danger;
            box-shadow: 0 0 0 2px $brand-danger, 1.5em 0 0 2px $brand-success, 3em 0 0 2px $brand-warning;
        }
    }

    &__iframe-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: $white;

        iframe {
            width: 200%;
            height: 200%;
            border: 0;
            zoom: .5;
            transform: scale(.5);
            transform-origin: 0 0;
        }
    }

    &__image {
        @include img-fluid;
    }
}
